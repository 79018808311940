<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Terms and conditions</h1>
    <div v-html="terminos.body"></div>
    
  </div>
</template>

<script>
	export default {
		data(){
			return {
				terminos:{
					body:'',
				},

			}
		},

		methods:{
			getTerminos(){
				axios.get(tools.url("/api/terminos")).then((response)=>{
			    	this.terminos = response.data;
			    }).catch((error)=>{
			    });
			},
			
		},

		mounted(){
			this.getTerminos();
		}
	}
</script>
