<template lang="html">
    <div class="box-course-sample-2">
      <div class="col col-image">
        <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+ course.image_url +')' }"></div>
      </div>
  
      <div class="col col-description">
 
        <h6 class="c-name">{{course.category.name}}</h6>
        <h4 class="c-desc">{{ course.name }}</h4>
        <h6 class="c-name">ID: {{course.id}}</h6>
        <h6 class="c-update">Order date: <strong>{{course.created}}</strong></h6>
  

      </div>
  
      <div class="col col-progress">
        
  
        <div class="row _row" v-if="course.user_id == null">
          <div class="col col-label">
            <button class="btn btn-danger" style="width: 100%;" @click="sendInvitationModal(course.course_id,course.orders_id,course.id)"> Share this course</button>
          </div>
        </div>
  

        
      </div>
      
     
      <sweet-modal ref="modalInvitation">
          <b-form class="col-12 col-add-ons" @submit="sendInvitation">
              <b-form-group class="col-lg-12 cus-f-group-1" label="Name ">
                  <b-form-input type="text" v-model="formInvitation.name" required placeholder=""></b-form-input>
              </b-form-group>
  
              <b-form-group class="col-lg-12 cus-f-group-1" label="Email*">
                  <b-form-input type="text" v-model="formInvitation.email" required placeholder=""></b-form-input>
              </b-form-group>
  
              <div class="d-block text-center pt-2">
                <b-button type="submit" class="btn-outline-red- btn-sm f-w-600 px-4">Invite another user to take this course</b-button>
              </div>
  
          </b-form>
      </sweet-modal>
      <sweet-modal ref="modalSendRefer">
        <b-form class="col-12 col-add-ons" @submit="sendRefer">
  
              <b-form-group class="col-lg-12 cus-f-group-1" label="Emails (separated by commas)">
                  <b-form-input type="text" v-model="formRefer.emails" required placeholder=""></b-form-input>
              </b-form-group>
  
              <div class="d-block text-center pt-2">
                <b-button type="submit" class="btn-outline-red- btn-sm f-w-600 px-4">Send</b-button>
              </div>
  
          </b-form>
      </sweet-modal>
  
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
          <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
          <div v-html="modal.msg"></div>
          <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-success" slot="button" @click="closeModal">Ok</b-button>
          </div>
        </sweet-modal>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      course: {
        name: 'null',
        
        
      },
    },
  
    data(){
      return{
        showPurchaseBtn: false,
  
        formAddons: {
          narrator: false,
          textemail: false,
          standardShipping: false,
          fastShipping: false,
        },
        formInvitation:{
          name:null,
          email:null,
          courses_id:null,
          orders_id:null
        },
        modal:{
          msg:'',
          icon:'',
          block:false,
        },
        formRefer:{
            courses_id:null,
            emails:null
        },
        addOns: {
          hasAudioNarrator:false,
          hasAlert:false,
          hasStandardShipping:false,
          hasDayShipping:false
        },
      }
    },
  
    methods: {
      onSubmitAddons(event) {
        event.preventDefault();
      },
      sendInvitationModal(courses_id,orders_id,id){
          this.formInvitation.name = null;
          this.formInvitation.email = null;
          this.formInvitation.courses_id = courses_id;
          this.formInvitation.orders_id = orders_id;
          this.formInvitation.id = id;
          this.$refs.modalInvitation.open();
      },
      sendInvitation(event){
        
        event.preventDefault();
          this.modal.block = true;
          this.modal.icon = '';
          this.modal.msg = 'Processing, please wait...';
          this.$refs.modal.open();
  
          axios.post(tools.url("/api/sendinvitation"),this.formInvitation).then((response)=>{
            this.$refs.modalInvitation.close();
            //alert('Invitation sent successfully');
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Invitation sent successfully";
            this.$parent.getCourses();
          }).catch((error)=>{
            this.modal.block = false;
            this.$refs.modal.close();
            alert('Error sending invitation');
            //this.modal.msg = 'Error sending invitation';
            
          });
      },
      cancelAssignment(id){
        event.preventDefault();
          this.modal.block = true;
          this.modal.icon = '';
          this.modal.msg = 'Processing, please wait...';
          this.$refs.modal.open();
  
          axios.get(tools.url("/api/cancelAssignment/"+id)).then((response)=>{
  
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Assignment canceled successfully";
            this.$parent.getCourses();
          }).catch((error)=>{
            this.modal.block = false;
            this.$refs.modal.close();
            alert('Error ');
            //this.modal.msg = 'Error sending invitation';
            
          });
      },
      closeModal(){
        this.modal.block = false;
        this.$refs.modal.close();
        this.$refs.modalInvitation.close();
      },
      goToCourse(courses_id,orders_id,id){
        axios.get(tools.url("/api/saveAssignment/"+id)).then((response)=>{
  
          this.$router.push('/my-course/'+id);
        }).catch((error)=>{
        
  
        });
      },
      openModalRefer(courses_id){
          this.formRefer.courses_id = courses_id;
          this.formRefer.emails = null;
          this.$refs.modalSendRefer.open();
      },
      sendRefer(event){
        
        event.preventDefault();
          this.modal.block = true;
          this.modal.icon = '';
          this.modal.msg = 'Processing, please wait...';
          this.$refs.modal.open();
  
          axios.post(tools.url("/api/sendrefer"),this.formRefer).then((response)=>{
            this.$refs.modalSendRefer.close();
            //alert('Invitation sent successfully');
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Invitation sent successfully";
            this.formRefer.courses_id = null;
            this.formRefer.emails = null;
          }).catch((error)=>{
            this.modal.block = false;
            this.$refs.modal.close();
            alert('Error sending invitation');
            //this.modal.msg = 'Error sending invitation';
            
          });
        
      },
      toCart(){
  
       
        localStorage.setItem("cart_addons",JSON.stringify(this.addOns));
        this.$router.push('/cart/'+this.course.course_id+'/'+this.course.orders_id);
      } 
    },
  
    watch: {
      formAddons: {
        handler (val, oldVal) {
          for(const [key, value] of Object.entries(val)){
            // console.log(value)
            if(value == true){
              this.showPurchaseBtn = true;
              break;
            }else{
              this.showPurchaseBtn = false;
            }
          }
        },
        deep: true
      }
    }
  }
  </script>
  