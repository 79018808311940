<template lang="html">
  <div id="enroll-page">

    <section class="main-section">
      <div class="box-main-title">
        <h1 class="title-st-1">Cart</h1>
      </div>

      <b-form class="container oversized-container main-container" @submit="onSubmit">
        <div class="row justify-content-center" v-if="step == 1">

          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white h-100">
              <div class="row pt-2">
                <div class="col-12">
                  <h5 class="box-title">Account information</h5>
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1" label="First name *">
                  <b-form-input type="text" v-model="form.user.nombre" required placeholder="" disabled ></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Last name *">
                  <b-form-input type="text" v-model="form.user.apellidos" required placeholder="" disabled></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-12 cus-f-group-1" label="Professional / Occupational License Number *">
                  <b-form-input type="text" v-model="form.user.licencia" required placeholder="" disabled></b-form-input>
                </b-form-group>

                <b-form-group class="col-xl-6 cus-f-group-1" label="Email *">
                  <b-form-input type="email_cart" v-model="form.user.email" required placeholder="" disabled></b-form-input>
                </b-form-group>


                <b-form-group class="col-lg-6 cus-f-group-1" label="Phone number">
                  <b-form-input type="text" minlength="8" maxlength="12" v-model="form.user.telefono" placeholder="" disabled></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white">
              <div class="row pt-2">
                <div class="col-12">
                  <h5 class="box-title">Billing information</h5>
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Address *">
                  <b-form-input type="text" v-model="form.address" required placeholder="" name="address" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Address line 2">
                  <b-form-input type="text" v-model="form.addressTwo" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-4 cus-f-group-1" label="State *">
                  <b-form-select v-model="form.state_id">
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s,indx) in states" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group>

                <!-- <b-form-group class="col-lg-4 cus-f-group-1" label="City *">
                  <b-form-select v-model="form.town_id">
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s,indx) in towns" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group> -->

                <b-form-group class="col-lg-4 cus-f-group-1" label="City *">
                  <b-form-input type="text" v-model="form.town" required placeholder="" name="town" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-4 cus-f-group-1" label="ZIP *">
                  <b-form-input type="text" v-model="form.zip_code" required placeholder="" name="zip_code" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <div class="col-12">
                  <hr class="mt-0" />
                </div>

                <div class="col-12">
                  <h5 class="box-title">Payment information</h5>
                </div>

                <div class="col-lg-6 mb-3 mb-lg-0 text-center text-lg-left align-self-center">
                  <img class="mb-lg-4 img-payments" src="/public/images/shared/payments.svg">
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1 custom-promo-group" label="Have a promo code?">
                  <b-form-input type="text" v-model="form.coupon" placeholder="Type your code here"></b-form-input>
                  <b-button type="button" class="btn-add-promo" @click="checkCoupon">Add</b-button>
                </b-form-group>

                <!-- <b-form-group class="col-lg-12 cus-f-group-1" label="Name in the card *">
                  <b-form-input type="text" v-model="form.user.nombre" required placeholder="" name="nombre_card" oninvalid="this.setCustomValidity('Complete this field')"></b-form-input>
                </b-form-group> -->

                <b-form-group class="col-lg-5 cus-f-group-1" label="Card Number* ( 15 or 16 digits )">
                  <b-form-input type="text" minlength="15" maxlength="16" v-model="form.cardNo" required="required" placeholder="" name="cardNo" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col cus-f-group-1 exp-date" label="Exp. date *">
                  <b-form-select required v-model="form.cardMonth" name="cardMonth" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>00</b-form-select-option>
                    <b-form-select-option value="1">01</b-form-select-option>
                    <b-form-select-option value="2">02</b-form-select-option>
                    <b-form-select-option value="3">03</b-form-select-option>
                    <b-form-select-option value="4">04</b-form-select-option>
                    <b-form-select-option value="5">05</b-form-select-option>
                    <b-form-select-option value="6">06</b-form-select-option>
                    <b-form-select-option value="7">07</b-form-select-option>
                    <b-form-select-option value="8">08</b-form-select-option>
                    <b-form-select-option value="9">09</b-form-select-option>
                    <b-form-select-option value="10">10</b-form-select-option>
                    <b-form-select-option value="11">11</b-form-select-option>
                    <b-form-select-option value="12">12</b-form-select-option>
                  </b-form-select>

                  <div class="line">/</div>

                  <b-form-select required v-model="form.cardYear" name="cardYear" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>0000</b-form-select-option>
                    <b-form-select-option :value="currentYear + yInx" v-for="(n, yInx) in 11" :key="'yInx-'+yInx">{{ currentYear + yInx }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col cus-f-group-1" label="CVV *">
                  <b-form-input type="text" minlength="2" maxlength="4" v-model="form.cardCvv" required placeholder="" name="cardCvv" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

              </div>
            </div>
          </div>
          <!--  -->
          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white">
              <div class="row pt-2">
                <div class="col-12">
                  <h5 class="box-title">Items</h5>
                </div>

                <div class="col-12">
                  <div class="box-add-ons">

                    <div class="row" v-if="course.course.hasAudioNarrator == 1 && course.details.hasAudioNarrator == 0">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          class="cus-f-checkbox-2"
                          v-model="form.narrator"
                          name="checkbox-addons-1"
                          :value="true"
                          :unchecked-value="null"
                          >
                          <span>Audio Narrator</span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.course.audioNarratorPrice) }} USD
                      </div>
                    </div>

                    <div class="row" v-if="course.course.hasAlert == 1 && course.details.hasAlert == 0">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          class="cus-f-checkbox-2"
                          v-model="form.textemail"
                          name="checkbox-addons-2"
                          :value="true"
                          :unchecked-value="null"
                          >
                          <span>Text/Email alert upon submission of credits to the state</span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.course.alertPrice) }} USD
                      </div>
                    </div>


                    <div class="row" v-if="course.course.hasStandardShipping == 1 && course.details.hasStandardShipping == 0">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          :disabled="(form.fastShipping)"
                          class="cus-f-checkbox-2"
                          v-model="form.standardShipping"
                          name="checkbox-addons-3"
                          :value="true"
                          :unchecked-value="false">
                          <span>Professionally printed certificate: <strong class="f-w-600">Standard Shipping</strong></span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.course.standardShippingPrice) }} USD
                      </div>
                    </div>

                    <div class="row" v-if="course.course.hasDayShipping == 1  && course.details.hasDayShipping == 0">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          :disabled="(form.standardShipping)"
                          class="cus-f-checkbox-2"
                          v-model="form.fastShipping"
                          name="checkbox-addons-3"
                          :value="true"
                          :unchecked-value="false">
                          <span>Professionally printed certificate: <strong class="f-w-600">1-2 Day Shipping</strong></span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.course.dayShippingPrice) }} USD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6">
            <div class="box-white box-course-info">
              <div class="row">
                <div class="col-12">
                  <h5 class="box-title">Added items</h5>
                </div>

                <div class="col-md-5 col-image">
                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+ course.course.image_url + ')' }">
                    <img src="/public/images/pages/courses/img-course.png">
                  </div>
                </div>

                <div class="col-md-7 mt-2 mt-md-0 pl-md-1 col-text">
                  <!-- <h6 class="c-name">Course title</h6> -->
                  <h6 class="c-req">
                      <span v-if="form.narrator == 1">Audio Narrator</span><br>
                      <span v-if="form.textemail == 1">Text/Email alert upon submission of credits to the state</span><br>
                      <span v-if="form.fastShipping == 1">1-2 Day Shipping</span><br>
                      <span v-if="form.standardShipping == 1">Standard Shipping</span><br>

                  </h6>
                  <h4 class="c-desc">{{course.course.name}}</h4>



                  <!-- <h6 class="c-cer">Certificate emailed immediately upon completion.</h6> -->

                  <div class="row box-button">
                    <div class="col-lg-5 col-more">
                      <router-link class="c-more" target="_blank" :to="'/course/checkout/'+course.course.state+'/'+course.course.name">See more information</router-link>
                    </div>

                    <!-- <div class="col-lg-7">
                      <span class="box-price">$29.99 USD</span>
                    </div> -->
                  </div>
                </div>

                <div class="col-12 col-text">
                  <hr class="mt-4" />
                  <h5 class="box-title">Order summary</h5>

                  <table class="table-summary">
                    <tbody>
                      <tr>
                        <th>Price</th>
                        <td>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.subtotal) }} USD</td>
                      </tr>

                      <!-- <tr>
                        <th>Add-ons</th>
                        <td>$0.00 USD</td>
                      </tr> -->

                      <tr>
                        <th>Total to pay</th>
                        <td><span class="text-red">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total) }} USD</span></td>
                      </tr>
                    </tbody>
                  </table>

                  <b-form-checkbox class="mt-3 cus-f-checkbox" v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                    I accept the <router-link target="_blank" to="/terms">terms and conditions</router-link> of 1 Click Edu
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12"></div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6">
            <div class="d-block box-submit">
              <b-button type="submit" class="btn-submit">Purchase items</b-button>
            </div>
          </div>
          <!--  -->

        </div>

        <div class="row" v-if="step == 2">
          <!-- Column -->
          <div class="col-lg-12 mb-4">
            <div class="box-white box-thanks h-100">
              <p class="mb-3">
                <i class="fal fa-check-circle check-icon"></i>
              </p>

              <!-- <h3 class="txt-purchased">You have purchase 1 course:</h3> -->
              <h2 class="txt-course-name" v-if="form.narrator == 1">Audio Narrator</h2>
              <h2 class="txt-course-name" v-if="form.textemail == 1">Text/Email alert upon submission of credits to the state</h2>
              <h2 class="txt-course-name" v-if="form.fastShipping == 1">Professionally printed certificate: 1-2 Day Shipping</h2>
              <h2 class="txt-course-name" v-if="form.standardShipping == 1">Professionally printed certificate: Standard Shipping</h2>




              <h5 class="txt-operation-id">Operation ID: <u>{{order_id}}</u></h5>

              <p class="mt-4">You will receive an email confirmation</p>

              <p class="mt-4">
                <router-link class="btn btn-red- f-w-600" to="/account">My account</router-link>
              </p>
            </div>
          </div>
          <!--  -->
        </div>
      </b-form>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>

      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      step: 1,

      showpass:false,
      showpasstwo:false,

      currentYear: 0,

      form: {
        user:{},
        narrator:null,
        textemail:null,
        standardShipping:null,
        fastShipping:null,


        firstName: null,
        cardMonth: null,
        cardYear: null,

        coupons_id:null,
        total_discount:0,
        total_adds:0,
        total:null,
        subtotal:null,
        state_id:null,
        town_id:null,
        previous_order_id:null,
        courses_id:null,
        town:null
      },
      states: [],
      towns: [],
      course:{
        course:{
          hasStandardShipping:1,
          hasDayShipping:1,

        },
        details:{
          hasStandardShipping:1,
          hasDayShipping:1
        },
        order:{},
        user:{}
      },
      coupon:{},
      modal:{
        msg:'Loading',
        icon:'',
        block:false,
      },
      order_id:null,
      course_id:null,
    }
  },

  methods: {
      onSubmit(event) {
        event.preventDefault();

        if(this.step == 2){
          //
        }

        if(this.step == 1){
          // acciones ...
          window.scrollTo(0, 0);
          this.createOrder();
        }
      },
      getInfo(){
        this.form.user = this.$root.user;
        this.form.cardName = this.form.user.nombre +' '+this.form.user.apellidos;
        if (this.$root.user.lastotder != null) {
              this.form.address = this.$root.user.lastotder.street;
              this.form.addressTwo = this.$root.user.lastotder.streetTwo;
              this.form.state_id = this.$root.user.lastotder.state_id;
              this.form.town = this.$root.user.lastotder.town;
              this.form.zip_code = this.$root.user.lastotder.zipcode;

          }
      },
      getStates(){
        axios.get(tools.url('/api/statesstates')).then((response)=>{
          this.states = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },

    getTowns(){
      if(this.form.state_id){
        axios.get(tools.url('/api/towns/' + this.form.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },
    getCourse(){
      axios.get(tools.url("/api/order/"+this.$route.params.order_id+"/"+this.$route.params.course_id)).then((response)=>{
          this.course = response.data;
          this.form.courses_id = response.data.course.id;
          this.form.previous_order_id = this.$route.params.order_id;
        }).catch((error)=>{
        });
    },

    checkCoupon(){

      axios.post(tools.url("/api/checkcoupon"),{coupon:this.form.coupon}).then((response)=>{
          this.coupon = response.data;
          this.form.coupons_id = response.data.id;

          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Accepted code";
          this.$refs.modal.open();


        }).catch((error)=>{
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = error.response.data.msg;
          this.$refs.modal.open();
        });
    },
    createOrder: function(){

        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Processing, please wait...';
        this.$refs.modal.open();

        var formData = new FormData();

        formData.append('order', JSON.stringify(this.form));
        formData.append('user', JSON.stringify(this.form.user));
        formData.append('userLogin', this.$root.logged);


        axios.post(tools.url('/api/order/storeitem'),formData).then((response)=>{
            if(response.data.type === "success"){
                this.order_id = response.data.order_id;
                this.$refs.modal.close();
                window.scrollTo(0, 0);
                this.step = 2;
            }else{
                this.$refs.modal.close();
                alert(response.data.error);
            }
        }).catch((error)=>{

            this.modal.icon="error";
            this.modal.msg = "Failed to place the order., Check your internet and reload the page";
            this.modal.block = false;


        });
    },
    getCart: function(){
      var storage = localStorage.getItem('cart_addons');
      if(storage != null){
        var datata = JSON.parse(storage);
        this.form.narrator = datata.hasAudioNarrator;
        this.form.textemail = datata.hasAlert;
        this.form.fastShipping = datata.hasDayShipping;
        this.form.standardShipping = datata.hasStandardShipping;


      }
    },


  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
        document.getElementById("i-1").type="text";
      }
      else if (val == false) {
        document.getElementById("i-1").type="password";
      }
    },

    'showpasstwo':function (val) {
      if (val == true) {
        document.getElementById("i-2").type="text";
      }
      else if (val == false) {
        document.getElementById("i-2").type="password";
      }
    },
    'form.state_id':function (val) {
        this.getTowns();
    },
    form: {
      handler(val){
          var tootal = 0;
          if (this.form.fastShipping == 1) {
            tootal += parseFloat(this.course.course.dayShippingPrice);
          }
          if (this.form.standardShipping == 1) {
            tootal += parseFloat(this.course.course.standardShippingPrice);
          }
          if (this.form.textemail == 1) {
            tootal += parseFloat(this.course.course.alertPrice);
          }
          if (this.form.narrator == 1) {
            tootal += parseFloat(this.course.course.audioNarratorPrice);
          }



          if(this.form.coupons_id != null){
              this.form.total_discount = parseFloat( parseFloat(tootal) * (this.coupon.percentage / 100) );
          }
          else{
            this.form.total_discount = 0;
          }
          this.form.subtotal = parseFloat(tootal);
          this.form.total = parseFloat(tootal) - this.form.total_discount;


      },
      deep: true
    },

  },


  mounted(){

    this.$refs.modal.open();
    this.getCart();
    this.getStates();
    this.getCourse();
    this.currentYear = new Date().getFullYear();
    var self = this;
    setTimeout(function(){
      self.getInfo();
    }, 1000);

    this.$refs.modal.close();
  }
}
</script>
