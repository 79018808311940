<template lang="html">
  <section class="why-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h2 class="sec-title">Why 1 Click Edu?</h2>
          <p>
            1 Click Edu prides itself on delivering high quality, easy to understand educational materials to our students
          </p>

          <p class="mt-3">
            <router-link to="/courses">Check out our courses and try us today</router-link>
          </p>

          <hr class="line" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
