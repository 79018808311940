<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="login()">

            <h1 class="mb-3">Log in</h1>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required placeholder="Email"></b-form-input>
            </b-form-group>

            <b-form-group class="cus-f-group-2 c-f-g-pass">
              <b-form-input id="i-1" type="password" v-model="form.password" required placeholder="Password"></b-form-input>
              <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpass, 'fa-eye-slash' : !showpass }"  @click="showpass = !showpass"></i>
            </b-form-group>

            <p class="mb-3">
              <router-link to="/access/reset-password">Forgot your password?</router-link>
            </p>

            <b-form-group>
              <b-button type="submit" class="btn-blue" variant="primary">Log in</b-button>
            </b-form-group>

            <hr class="my-3" />
            <p class="text-center">
              <span>Do you need an account?</span>
              <router-link class="f-w-700" to="/access/sign-up"> <i class="fas fa-user"></i> Sign up </router-link>
            </p>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: '',
        password: ''
      },

      showpass: false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    login(){
      this.modal.icon = "";
      this.modal.msg = 'Loading...';
      this.modal.block = true;
      this.$refs.modal.open();


      
      axios.get(tools.url('/sanctum/csrf-cookie')).then(() => {
         axios.post(tools.url("/login"), this.form).then((response)=>{
            this.modal.icon = "";
            this.modal.msg = '';
            this.modal.block = false;
            this.$refs.modal.close();

             this.$parent.user = response.data;
             this.$parent.logged = true;
             this.$root.auth();
             this.$router.push('/account/my-courses');
          }).catch((error)=>{
              console.log(error.response.data.error);
              this.modal.icon = "error";
              this.modal.msg = 'Wrong credentials';
              this.modal.block = false;
              
         });
      });
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Unknown error.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  mounted(){
    if(this.logged){
      this.$router.push("/account");
    }
  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
        document.getElementById("i-1").type="text";
      }
      else if (val == false) {
        document.getElementById("i-1").type="password";
      }
    },
  },
}
</script>
