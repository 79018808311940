<template lang="html">
  <div id="contact-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-form">
            <div class="box-form">
              <h1 class="title-st-1">Contact us</h1>
              <!-- <p>We want to heard you, send us a message</p> -->

              <b-form class="row" @submit="onSubmit">
                <b-form-group class="col-lg-6 cus-f-group-1" label="First name">
                  <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Last name">
                  <b-form-input type="text" v-model="form.lastName" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-6 cus-f-group-1" label="Email">
                  <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
                </b-form-group>
                <b-form-group class="col-6 cus-f-group-1" label="Phone">
                  <b-form-input  v-model="form.phone" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-12 cus-f-group-1" label="State">
                  <!-- <b-form-input v-model="form.state" required placeholder=""></b-form-input> -->

                  <b-form-select v-model="form.state" required>
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>

                    <b-form-select-option :value="s.name" v-for="(s,indx) in states" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group>
                

                <b-form-group class="col-12 cus-f-group-1" label="Course">
                  <b-form-select v-model="form.course" required>
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>

                    <b-form-select-option :value="s.name" v-for="(s,indx) in courses" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group>


                <b-form-group class="col-12 cus-f-group-1 mb-3" label="Message">
                  <b-form-textarea
                    required
                    v-model="form.msg"
                    placeholder=""
                    rows="4"
                    max-rows="4"
                  ></b-form-textarea>
                </b-form-group>

                <div class="col-12">
                  <p class="text-center">
                    <b-button type="submit" class="btn-send">Send</b-button>
                  </p>
                </div>
              </b-form>
            </div>
          </div>

          <div class="col-lg-6 col-image">
            <div class="placed-backg image"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
        msg: null,
        state:null,
        course:null,
      },
      states:[],
      courses:[],
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Message sent');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
    getStates(){
      axios.get(tools.url("/api/states")).then((response)=>{
          this.states = response.data;
        }).catch((error)=>{
        });
    },
    getCourses(){
      axios.get(tools.url("/api/courses")).then((response)=>{
          this.courses = response.data;

        }).catch((error)=>{
        });
    },
  },
  mounted(){
    this.getStates();
    this.getCourses();
  }
}
</script>
