<template lang="html">
<div>
  <b-form @submit.prevent="onSubmit()">
    <h6 class="f-w-600">MY INFORMATION</h6>
    <hr class="mb-4 c-hr" />

    <div class="row">
      <b-form-group class="col-12 cus-f-group-1" label="First name *">
        <b-form-input type="text" v-model="$root.user.nombre" required placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="Last name *">
        <b-form-input type="text" v-model="$root.user.apellidos" required placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="Professional / Occupational License Number *">
        <b-form-input type="text" v-model="$root.user.licencia" required placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="Email *">
        <b-form-input type="email" v-model="$root.user.email" required placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="Phone number">
        <b-form-input type="text" v-model="$root.user.telefono" placeholder=""></b-form-input>
      </b-form-group>


      <b-form-group class="col-12 cus-f-group-1" label="Address">
        <b-form-input type="text" v-model="$root.user.direccion" placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="Address line 2">
        <b-form-input type="text" v-model="$root.user.direccion_dos" placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="State *">
          <b-form-select v-model="$root.user.estado">
            <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
            <b-form-select-option :value="s.id" v-for="(s,indx) in states" :key="indx">{{s.name}}</b-form-select-option>             
          </b-form-select>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="City">
        <b-form-input type="text" v-model="$root.user.ciudad" placeholder=""></b-form-input>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1" label="ZIP">
        <b-form-input type="text" v-model="$root.user.cp" placeholder=""></b-form-input>
      </b-form-group>
    </div>

    <b-button type="submit" class="btn-red btn-form" variant="primary" :disabled="disabled">Save information</b-button>
  </b-form>

  <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
    <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
    {{modal.msg}}
    <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
    <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
    </div>
  </sweet-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
      user:{},

      states: [],
      towns: [],
      disabled: false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  watch:{
    'user.customer.state_id':function(val){
      if(val && !isNaN(val)){
        //this.getTowns(val);
      }
    }
  },

  methods: {
    onSubmit(){
      var data = {
        id: this.$root.user.id,
        nombre: this.$root.user.nombre,
        apellidos: this.$root.user.apellidos,
        licencia: this.$root.user.licencia,
        email: this.$root.user.email,
        telefono: this.$root.user.telefono,

        direccion: this.$root.user.direccion,
        direccion_dos: this.$root.user.direccion_dos,
        estado: this.$root.user.estado,
        ciudad: this.$root.user.ciudad,
        cp: this.$root.user.cp,
      
      };

      this.disabled = true;

      axios.post(tools.url('/api/user/profile'), data).then((response)=>{
        if(response.data.status == 'success'){
          this.disabled = false;
          this.modal.icon = "success";
          this.modal.msg = response.data.msg;
          this.modal.block = false;
          this.$refs.modal.open();
          this.$root.auth();
        }else{
          this.disabled = false;
          this.modal.icon = "error";
          this.modal.msg = response.data.msg;
          this.modal.block = false;
          this.$refs.modal.open();
        }
      }).catch((error)=>{
        this.disabled = false;
        this.handleErrors(error);
      });
    },

    handleErrors:function(errors){
      var err="";

      if (errors.response) {
        if(errors.response.data.errors){
          jQuery.each(errors.response.data.errors,(k,v)=>{
            err+="*"+v[0]+"\n";
          });
        }
      }
      else{
        console.log(errors);
        err="Error desconocido.";
      }

      this.modal.icon = "error";
      this.modal.msg = err;
      this.modal.block = false;
      this.$refs.modal.open();
    },
    getStates(){
        axios.get(tools.url('/api/statesstates')).then((response)=>{
          this.states = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
  },

  beforeMount(){
     this.getStates();
  },

  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/");
     }
    // if(this.user.address && this.user.address.state_id){
    //   this.getTowns(this.user.address.state_id);
    // }
    
  }
}
</script>
