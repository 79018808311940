<template lang="html">
    <div id="courses-results-page">
  
      <section class="container oversized-container results-section">
        <div class="row">
          <div class="col-12 col-title">
            <div class="box-simple-title">
              <h6>Blog</h6>
            </div>
          </div>
        </div>
  
        <div class="row row-items-xl-4" >
          <div class="col-md-6 col-lg-4 mb-4 box-course-sample-1" v-for="(c, cInx) in blogs" :key="'cInx-'+cInx">
            <router-link class="box-course" :to="'/blogs/'+c.id">
              <div class="box-image" v-bind:style="{ backgroundImage: 'url('+c.imageUrl+')' }">
              </div>
  
              <div class="box-description">
                <h6 class="c-name">{{c.title}}</h6>
                
              </div>
            </router-link>
          </div>
        </div>
      </section>
  
      <WhyComponent></WhyComponent>
  
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
        blogs: [],
      }
  
    },
    methods: {
  
      getBlogs(){
        axios.get(tools.url("/api/blogs")).then((response)=>{
            this.blogs = response.data;
    
          }).catch((error)=>{
          });
      },
  
    },
  
    mounted(){
  
      this.getBlogs();
    
    },
  }
  </script>
  