<template lang="html">
  <div id="about-us-page">

    <section class="top-section">
      <div class="container oversized-container">
        <h1 class="mb-4 title-st-1">About us</h1>

        <div class="row align-items-center">
          <div class="col-lg-6 col-xl-5 col-image">
            <img src="/public/images/pages/about-us/image.jpg">
          </div>

          <div class="col-lg-6 col-xl-6 col-text">
            <h4 class="title">A little bit about us</h4>

            <p>
              1 Click Edu was established to offer high-quality online educational courses to our students on the web.
            </p>
            <p class="mt-3">
              Rest assured, all our courses not only meet but exceed state requirements.
            </p>
            <p class="mt-3">
              Give us a try; your satisfaction is guaranteed!
            </p>

          </div>
        </div>
      </div>
    </section>

    <!-- <section class="middle-section">
      <div class="container oversized-container">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ipsum nisi, vitae interdum lectus vestibulum eget. Nunc tristique mollis lacus, ac facilisis lacus ultrices nec. Vivamus mi odio, suscipit id libero vel, consectetur mollis leo. Aenean ac massa a erat vehicula euismod ac ut turpis. Curabitur suscipit, lorem ut tristique ultricies, sapien tortor sodales turpis, eget ultricies risus mi ut magna. Etiam id nisl pretium, sollicitudin arcu ut, aliquet nisi. Duis iaculis sapien nulla, sit amet porta nulla hendrerit ac. Pellentesque placerat in ipsum vitae dapibus.
        </p>

        <p class="mt-4">
          Donec imperdiet maximus diam, nec aliquet nisi placerat venenatis. In urna justo, congue sit amet lectus ac, fringilla auctor est. Phasellus euismod, mauris non pulvinar varius, augue purus sollicitudin diam, sed rhoncus orci augue sed massa. Duis quam lorem, dignissim nec placerat vitae.
        </p>
      </div>
    </section> -->

    <section class="container oversized-container info-s1-section">
      <div class="row align-items-center">
        <div class="col-lg-3 col-about-us">
          <h2>Our numbers tell more about us</h2>
        </div>

        <div class="col-lg-3 col-num">
          <h3 class="number" v-html="about.body"></h3>
          <p>
            Different types of approved continuing education online courses, to meet all of your renewal needs!
          </p>
        </div>

        <div class="col-lg-3 col-num">
          <h3 class="number">Over</h3>
          <p>
            1,000 students renewing their licenses with us every year.
          </p><br>
        </div>

        <div class="col-lg-3 col-num">
          <h3 class="number">24/7</h3>
          <p>
            Take our courses anytime, anywhere, from the comfort of your own home!
          </p><br>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
    data(){
        return {
          about:{
                body:'',
            },
        }
    },
    methods:{
        getInfo(){
            axios.get(tools.url("/api/aboutus")).then((response)=>{
                this.about = response.data;
            }).catch((error)=>{
            });
        },
    },
    mounted(){
        this.getInfo();
    }
}
</script>
