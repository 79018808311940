<template lang="html">
  <div class="box-course-sample-2">
    <div class="col col-image">
      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+ course.image_url +')' }"></div>
    </div>

    <div class="col col-description">
      <div class="status">Active <i class="far fa-check-circle"></i></div><br>
      <h6 class="c-name">{{course.category.name}}</h6>
      <h4 class="c-desc">{{ course.name }}</h4>
      <!-- <h6 class="c-name">ID: {{course.id}}</h6> -->
      <h6 class="c-update">Order date: <strong>{{course.created}}</strong></h6>

      <div class="row no-gutters box-progress">
        <div class="col col-number">
          {{course.progress}}%
        </div>

        <div class="col col-bar">
          <b-progress height="6px" :value="course.progress" :max="100"></b-progress>
        </div>
      </div>

      <h6 class="c-update">Last update: <strong>{{course.last_update}}</strong></h6>

      <div class="row no-gutters box-buttons" v-if="course.user_id == $root.user.id || course.user_id == null">
        <div class="col-12">
          <a v-if="course.disabled == 0" class="_btn bg-red" style="width: 140px;" @click="goToCourse(course.course_id,course.orders_id,course.id)">Launch Course</a>
          <a v-else class="_btn bg-red" style="width: 140px;">Locked course</a>
        </div>

        <!-- <div class="col-sm-6 text-sm-right">
          <a class="_btn outline-black">See Add-ons</a>
        </div> -->
      </div>
    </div>

    <div class="col col-progress">
      <h6 class="_title">Status</h6>

      <div class="row _row">
        <div class="col col-label">
          <div class="txt icon-1">Course<br />progress</div>
        </div>

        <div class="col col-bubble completed">
          <div class="bubble">{{course.modules_complete}} section(s) completed</div>
        </div>
      </div>

      <div class="row _row">
        <div class="col col-label">
          <div class="txt icon-2">Time<br />spent</div>
        </div>

        <div class="col col-bubble">
          <div class="bubble">{{course.time_session}} minutes</div>
        </div>
      </div>

      <div class="row _row">
        <div class="col col-label">
          <div class="txt icon-3">Course<br />completed</div>
        </div>

        <div class="col col-bubble">
          <div class="bubble">{{course.status}}</div>
        </div>
      </div>

      <!-- <div class="row _row" v-if="course.user_id == null">
        <div class="col col-label">
          <button class="btn btn-danger" style="width: 100%;" @click="sendInvitationModal(course.course_id,course.orders_id,course.id)"> Send invitation</button>
        </div>
      </div> -->

      <div class="row _row">
        <div class="col col-label">
          <button class="btn btn-danger" style="width: 100%;" @click="openModalRefer(course.course_id)"> Refer a friend, get $5!</button>
        </div>
      </div>

      
    </div>
    <div class="col-12 col-add-ons" v-if="course.user_id != $root.user.id && course.user_id != null">
        <p><b>Assignated to: </b> {{ course.customer_name}} {{ course.customer_lastname}} - {{ course.customer_email}} | <b>Assignated date:</b> {{ course.assigned_date}}</p>
        <button class="btn btn-secondary" style="width: 30%;" @click="cancelAssignment(course.id)"> Cancel assignment</button>
    </div>
    <b-form class="col-12 col-add-ons" @submit="onSubmitAddons" v-if="course.course_hasStandardShipping || course.course_hasAlert || course.course_hasStandardShipping || course.course_hasDayShipping">
      <h6 class="_title">Add-ons</h6>

      <!-- Box add-ons -->
      <div class="box-add-ons">
        <div class="row" v-if="course.course_hasAudioNarrator">
          <div class="col-sm col-text" v-if="course.hasAudioNarrator">
            <b-form-checkbox
              class="cus-f-checkbox-2 control-sm"
              v-model="course.hasAudioNarrator"
              name="checkbox-addons-1"
              :value="true"
              :unchecked-value="false"
              disabled>
              <span>Audio Narrator | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.audioNarratorPrice) }} USD</span>
            </b-form-checkbox>
          </div>
          <div class="col-sm col-text" v-else>
            <b-form-checkbox
              class="cus-f-checkbox-2 control-sm"
              v-model="addOns.hasAudioNarrator"
              name="checkbox-addons-1"
              :value="true"
              :unchecked-value="false"
              >
              <span>Audio Narrator | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.audioNarratorPrice) }} USD</span>
            </b-form-checkbox>
          </div>

        </div>

        <div class="row" v-if="course.course_hasAlert">
          <div class="col-sm col-text" v-if="course.hasAlert">
            <b-form-checkbox
              class="cus-f-checkbox-2 control-sm"
              v-model="course.hasAlert"
              name="checkbox-addons-2"
              :value="true"
              :unchecked-value="false"
              disabled>
              <span>Text/Email alert upon submission of credits to the state | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.alertPrice) }} USD</span>
            </b-form-checkbox>
          </div>

          <div class="col-sm col-text" v-else>
            <b-form-checkbox
              class="cus-f-checkbox-2 control-sm"
              v-model="addOns.hasAlert"
              name="checkbox-addons-2"
              :value="true"
              :unchecked-value="false"
              >
              <span>Text/Email alert upon submission of credits to the state | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.alertPrice) }} USD</span>
            </b-form-checkbox>
          </div>

    
        </div>

        <div class="row" v-if="course.course_hasStandardShipping">
          <div class="col-sm col-text" v-if="course.hasStandardShipping">
            <b-form-checkbox
              
              class="cus-f-checkbox-2 control-sm"
              v-model="course.hasStandardShipping"
              name="checkbox-addons-3"
              :value="true"
              :unchecked-value="false"
              disabled>
              <span>Professionally printed certificate: <strong class="f-w-600">Standard Shipping</strong> | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.standardShippingPrice) }} USD</span>
            </b-form-checkbox>
          </div>
          <div class="col-sm col-text" v-else>
            <b-form-checkbox
              
              class="cus-f-checkbox-2 control-sm"
              v-model="addOns.hasStandardShipping"
              name="checkbox-addons-3"
              :value="true"
              :unchecked-value="false"
              :disabled="(addOns.hasDayShipping)">
              <span>Professionally printed certificate: <strong class="f-w-600">Standard Shipping</strong> | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.standardShippingPrice) }} USD</span>
            </b-form-checkbox>
          </div>
          
        </div>

        <div class="row" v-if="course.course_hasDayShipping"> 
          <div class="col-sm col-text" v-if="course.hasDayShipping">
            <b-form-checkbox
         
              class="cus-f-checkbox-2 control-sm"
              v-model="course.hasDayShipping"
              name="checkbox-addons-3"
              :value="true"
              :unchecked-value="false"
              disabled>
              <span>Professionally printed certificate: <strong class="f-w-600">1-2 Day Shipping</strong> | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.dayShippingPrice) }} USD</span>
            </b-form-checkbox>
          </div>
          <div class="col-sm col-text" v-else>
            <b-form-checkbox
         
              class="cus-f-checkbox-2 control-sm"
              v-model="addOns.hasDayShipping"
              name="checkbox-addons-3"
              :value="true"
              :unchecked-value="false"
              :disabled="(addOns.hasStandardShipping)">
              <span>Professionally printed certificate: <strong class="f-w-600">1-2 Day Shipping</strong> | ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.dayShippingPrice) }} USD</span>
            </b-form-checkbox>
          </div>
   
        </div>

        <div class="d-block text-center pt-2" >
          <b-button type="submit" class="btn-outline-red- btn-sm f-w-600 px-4" @click="toCart">Purchase Add ons</b-button>
        </div>
      </div>
      <!--  -->
    </b-form>
    <sweet-modal ref="modalInvitation">
        <b-form class="col-12 col-add-ons" @submit="sendInvitation">
            <b-form-group class="col-lg-12 cus-f-group-1" label="Name ">
                <b-form-input type="text" v-model="formInvitation.name" required placeholder=""></b-form-input>
            </b-form-group>

            <b-form-group class="col-lg-12 cus-f-group-1" label="Email*">
                <b-form-input type="text" v-model="formInvitation.email" required placeholder=""></b-form-input>
            </b-form-group>

            <div class="d-block text-center pt-2">
              <b-button type="submit" class="btn-outline-red- btn-sm f-w-600 px-4">Invite another user to take this course</b-button>
            </div>

        </b-form>
    </sweet-modal>
    <sweet-modal ref="modalSendRefer">
      <b-form class="col-12 col-add-ons" @submit="sendRefer">

            <b-form-group class="col-lg-12 cus-f-group-1" label="Emails (separated by commas)">
                <b-form-input type="text" v-model="formRefer.emails" required placeholder=""></b-form-input>
            </b-form-group>

            <div class="d-block text-center pt-2">
              <b-button type="submit" class="btn-outline-red- btn-sm f-w-600 px-4">Send</b-button>
            </div>

        </b-form>
    </sweet-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-success" slot="button" @click="closeModal">Ok</b-button>
        </div>
      </sweet-modal>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      name: 'null',
      
      
    },
  },

  data(){
    return{
      showPurchaseBtn: false,

      formAddons: {
        narrator: false,
        textemail: false,
        standardShipping: false,
        fastShipping: false,
      },
      formInvitation:{
        name:null,
        email:null,
        courses_id:null,
        orders_id:null
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      formRefer:{
          courses_id:null,
          emails:null
      },
      addOns: {
        hasAudioNarrator:false,
        hasAlert:false,
        hasStandardShipping:false,
        hasDayShipping:false
      },
    }
  },

  methods: {
    onSubmitAddons(event) {
      event.preventDefault();
    },
    sendInvitationModal(courses_id,orders_id,id){
        this.formInvitation.name = null;
        this.formInvitation.email = null;
        this.formInvitation.courses_id = courses_id;
        this.formInvitation.orders_id = orders_id;
        this.formInvitation.id = id;
        this.$refs.modalInvitation.open();
    },
    sendInvitation(event){
      
      event.preventDefault();
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Processing, please wait...';
        this.$refs.modal.open();

        axios.post(tools.url("/api/sendinvitation"),this.formInvitation).then((response)=>{
          this.$refs.modalInvitation.close();
          //alert('Invitation sent successfully');
          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Invitation sent successfully";
          this.$parent.getCourses();
        }).catch((error)=>{
          this.modal.block = false;
          this.$refs.modal.close();
          alert('Error sending invitation');
          //this.modal.msg = 'Error sending invitation';
          
        });
    },
    cancelAssignment(id){
      event.preventDefault();
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Processing, please wait...';
        this.$refs.modal.open();

        axios.get(tools.url("/api/cancelAssignment/"+id)).then((response)=>{

          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Assignment canceled successfully";
          this.$parent.getCourses();
        }).catch((error)=>{
          this.modal.block = false;
          this.$refs.modal.close();
          alert('Error ');
          //this.modal.msg = 'Error sending invitation';
          
        });
    },
    closeModal(){
      this.modal.block = false;
      this.$refs.modal.close();
      this.$refs.modalInvitation.close();
    },
    goToCourse(courses_id,orders_id,id){
      axios.get(tools.url("/api/saveAssignment/"+id)).then((response)=>{

        this.$router.push('/my-course/'+id);
      }).catch((error)=>{
      

      });
    },
    openModalRefer(courses_id){
        this.formRefer.courses_id = courses_id;
        this.formRefer.emails = null;
        this.$refs.modalSendRefer.open();
    },
    sendRefer(event){
      
      event.preventDefault();
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Processing, please wait...';
        this.$refs.modal.open();

        axios.post(tools.url("/api/sendrefer"),this.formRefer).then((response)=>{
          this.$refs.modalSendRefer.close();
          //alert('Invitation sent successfully');
          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Invitation sent successfully";
          this.formRefer.courses_id = null;
          this.formRefer.emails = null;
        }).catch((error)=>{
          this.modal.block = false;
          this.$refs.modal.close();
          alert('Error sending invitation');
          //this.modal.msg = 'Error sending invitation';
          
        });
      
    },
    toCart(){

     
      localStorage.setItem("cart_addons",JSON.stringify(this.addOns));
      this.$router.push('/cart/'+this.course.course_id+'/'+this.course.orders_id);
    } 
  },

  watch: {
    formAddons: {
      handler (val, oldVal) {
        for(const [key, value] of Object.entries(val)){
          // console.log(value)
          if(value == true){
            this.showPurchaseBtn = true;
            break;
          }else{
            this.showPurchaseBtn = false;
          }
        }
      },
      deep: true
    }
  }
}
</script>
