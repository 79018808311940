<template lang="html">
  <div id="page-404">
    <section class="container">
      <p class="mb-3">
        <img class="logo img-fluid" src="/public/images/logo.png" alt="1 Click Edu">
      </p>
      <h1>Error 404</h1>
      <h2 class="mb-4">Page not found</h2>

      <p>
        <i class="fas fa-spinner fa-pulse fa-lg"></i>
      </p>
      <p class="lg">You will be redirected to the Home page</p>
      <p class="mt-2 lg">
        <router-link to="/"><i class="fas fa-home-alt"></i> <strong class="f-w-700">Go Home page now!</strong></router-link>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  data(){
    return{}
  },

  mounted(){
    setTimeout( ()=>{ this.$router.push('/'); }, 4000);
  }
}
</script>
