<template lang="html">
  <div class="_page">
    <div class="d-block">
      <i class="fas fa-graduation-cap icon"></i>
      <h5 class="title">Select a module to start</h5>

      <!-- <p class="modules">
        Modulos: 2/9
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
}
</script>
