<template lang="html">
    <div id="my-courses-page">
      <div>
        <h6 class="f-w-600">SHARE COURSE</h6>
        <p>Did you buy this course for someone else? Send it to them by email using the box below! Just be aware it will be removed from your courses once they have accepted it.</p>
        <hr class="mb-4 c-hr" />
    
        <div class="box-courses">
          <active-course-component :course="course" v-for="(course, cInx) in courses" :key="'cInx-'+cInx"></active-course-component>
        </div>
    
        <!-- <div class="box-more-courses">
          <img class="icon" src="/public/images/pages/courses/course-icon.png">
          <h6 class="txt">Learn more!</h6>
          <router-link class="txt text-red" to="/">See more courses</router-link>
        </div> -->
      </div>
    
    </div>
    </template>
    
    <script>
    import ActiveCourseComponent from './components/active-course-component-share.vue'
    export default {
      components: {
        ActiveCourseComponent
      },
    
      data(){
        return{
          formAddons: {
            narrator: null,
          },
    
          courses: [
          
          ],
        }
      },
    
      methods: {
        getCourses(){
          axios.get(tools.url("/api/user/mycoursesshared")).then((response)=>{
              this.courses = response.data;
              
            }).catch((error)=>{
            });
        },
      },
    
      beforeMount(){
      },
    
      mounted(){
         if(this.$root.logged == false){
             this.$router.push("/");
         }
         this.getCourses();
      }
    }
    </script>
    