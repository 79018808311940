<template>
  <div>
    <ol class="breadcrumb 2">
			<li><router-link to="/home"><i class="entypo-home"></i>Home</router-link></li>
			<li class="active"><strong>Cursos</strong></li>
		</ol>
		<h2 class="margin-bottom">Cursos</h2>

		<div class="row">
			<div class="col-md-12">
				<div id="toolbar">
            <router-link to="/courses/edit"><button class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Nuevo</button></router-link>
			      <button class="btn btn-danger btn-sm" @click="deleteNews()"><i class="fa fa-trash"></i> Borrar</button>
			    </div>
				<table id="table"></table>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
  export default {
  data(){
    return {
      data:[],
    }
  },
  methods:{
    mounthTable(){
      jQuery('#table').bootstrapTable({
        columns: [
          {
            field:"check",
            checkbox:true,
            align: 'center',
          },
          {
            field: 'image',
            title: 'Imagen',
            sortable:false,
            width:"52px",
          },
          {
            field: 'id',
            title: '#',
            sortable:false,
            switchable:true,
          },
          {
            field: 'name',
            title: 'Nombre',
            sortable:true,
            switchable:true,
          },
          {
            field: 'report',
            title: 'Se debe reportar',
            sortable:true,
            switchable:true,
          },
          {
            field: 'date',
            title: 'Fecha de registro',
            sortable:true,
            switchable:true,
          },
          {
            field: 'dateUpdated',
            title: 'Fecha de actualización',
            sortable:true,
            switchable:true,
          }


        ],
        showRefresh:true,
      });

      jQuery('#table').on('refresh.bs.table',()=>{
        this.getContent();
      });

      jQuery('#table').on('click-row.bs.table',(row,data)=>{
        this.$router.push('/courses/edit/'+data.id);
      });

      this.getContent();

    },

    getContent(){
      this.$parent.inPetition=true;
      axios.get(tools.url("/api/admin/courses")).then((response)=>{
          this.data = response.data;
          jQuery('#table').bootstrapTable('removeAll');
          jQuery('#table').bootstrapTable('append',this.data);
          this.$parent.inPetition=false;
        }).catch((error)=>{
            this.$parent.handleErrors(error);
            this.$parent.inPetition=false;
        });
    },

    deleteNews:function(){
      var rows=jQuery('#table').bootstrapTable('getSelections');
      if(rows.length==0){
        return false;
      }
      alertify.confirm("Alerta!","¿Seguro que deseas borrar "+rows.length+" registros?",()=>{
        this.$parent.inPetition=true;
        var params={};
        params.ids=jQuery.map(rows,(row)=>{
          return row.id;
        });

        axios.delete(tools.url('/api/admin/courses'),{data:params})
        .then((response)=>{
          this.$parent.showMessage(response.data.msg,"success");
          this.getContent();
          this.$parent.inPetition=false;
        })
        .catch((error)=>{
          this.$parent.handleErrors(error);
              this.$parent.inPetition=false;
        });
      },
      ()=>{
      });
    }
  },
      mounted() {
        this.mounthTable();
      }
  }
</script>
