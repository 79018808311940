<template lang="html">
  <div id="u-exam-page">

    <div class="box-top">
      <div class="box-title">
        <h6 class="title">{{ module.title }}</h6>
      </div>
    </div>

    <b-form @submit="onSubmit" class="box-exam">
      <div class="question-answer" v-for="(question, indexQ) in questions" :key="indexQ">
        <h5 class="question">{{ indexQ + 1 }} - {{ question.ask }}</h5>

        <b-form-group class="answers" required>
          <b-form-radio v-model=" question.response" v-for="(answer, indexR) in question.answers" :key="indexR" :name="'respuesta'+indexQ" :value="answer.id" required>{{ answer.answer }}</b-form-radio>
        </b-form-group>
      </div>

      <div class="d-block text-center">
        <b-button type="submit" class="btn-red- btn-course-action">Submit</b-button>
        <!-- <b-button type="submit" class="btn-red-">Siguiente capitulo</b-button> -->
      </div>
    </b-form>

    <sweet-modal :blocking="modal.block" :hide-close-button="modal.block" ref="modalRes" >
      <h5 class="f-w-600">
        Thank you
      </h5>

      <p class="mt-2 f-w-500" v-html="modal.msg"></p>
      <div class="col-12 mt-4 text-center" v-if="modal.icon == 'success'">
        <b-button class="btn btn-red- px-5 f-w-500" slot="button" @click="closeModalExam">Continue</b-button>
      </div>
      <div class="col-12 mt-4 text-center" v-if="modal.icon == 'warning'">
        <b-button class="btn btn-red- px-5 f-w-500" style="background-color: #241e1b;border-color: #241e1b;" slot="button" @click="closeModalExamTry">Try again</b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      respuestas: [],
      questions:[
    		
      ],

      module: {},

      user_has_courses_id: null,
      module_id: null,

      modal:{
        msg:'',
        icon:'',
        block:false
      }
    }
  },

  methods: {
    setVals(){
      this.user_has_courses_id = this.$route.params.user_has_courses_id;
      this.module_id = this.$route.params.module_id;
    },

    onSubmit(){
      event.preventDefault();

	  axios.post(tools.url('/api/user/checkExamen'), { questions: this.questions, module_id: this.module_id }).then((response)=>{
		this.modal.icon = response.data.status;
		this.modal.msg = response.data.msg;
		this.modal.block = true;
		this.$refs.modalRes.open();
      }).catch((error)=>{
        console.log(error);
      });

      
    },

    getContent(){
      axios.post(tools.url('/api/user/getExamen'), { user_has_courses_id: this.user_has_courses_id, module_id: this.module_id }).then((response)=>{
        this.module = response.data.module;
        
        this.questions = response.data.questions;
      }).catch((error)=>{
        console.log(error);
      });
    },

    closeModalExam(){
      this.modal.block = false;
      this.$refs.modalRes.close();
      this.$router.push('/my-course/'+this.user_has_courses_id+'/exam-finished/'+this.module_id);
    },
    closeModalExamTry(){
      this.modal.block = false;
      this.$refs.modalRes.close();
      
    }
  },

  watch: {
    "$route.params.id" : function(v){
      window.scrollTo(0, 0); // Subir hasta arriba del scroll
      this.setVals();
      // this.getContent();
    }
  },

  beforeMount(){
    window.scrollTo(0, 0); // Subir hasta arriba del scroll
    this.setVals();
     this.getContent();
  }
}
</script>
