<template lang="html">
  <div class="box-successful-exam">
    <!-- <i class="fal fa-times-circle btn-close"></i> -->
    <div class="box-success-check">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>

    <div class="box-content" v-if="documentUrl != null">
      <h4 class="completed">Course completed</h4>
      <h4 class="congratulations">CONGRATULATIONS</h4>
      <p>Your certificate of completion has been emailed to you. You can also download it here</p>
      <p class="mt-3">
        <a class="t-150 _btn" :href="documentUrl" target="_blank">Download the PDF certificate</a>

        <a class="t-150 _btn" v-if="order.hasship == true" @click="orderPrintend">Order a printed certificate direct to your home</a>

       
      </p>
      <p class="mt-3">
        <a class="t-150 _btn" v-if="order.hasallert == true" @click="orderText">Text/Email alert upon submission of credits to the state</a>
      </p>
      
    </div>
    <div class="box-content" v-else>
      <h4 class="completed">Section completed</h4>
      <!-- <h4 class="congratulations">CONGRATULATIONS</h4> -->

    </div>

    <sweet-modal :blocking="modal.block" :icon="modal.icon" :hide-close-button="modal.block" ref="modalRes" >

      <p class="mt-2 f-w-500" v-html="modal.msg"></p>
      <div class="col-12 mt-4 text-center" >
        <b-button class="btn btn-red- px-5 f-w-500" slot="button" @click="$refs.modalRes.close()">Continue</b-button>
      </div>
  
    </sweet-modal>

  </div>
</template>


<script>
export default {
  data(){
    return{
      respuestas: [],
      documentUrl:null,
      order:{
        id:null,

      },
      modal:{
        msg:'',
        icon:'',
        block:false
      }
    }
  },

  methods: {
    setVals(){
      this.user_has_courses_id = this.$route.params.user_has_courses_id;
      this.module_id = this.$route.params.module_id;
    },
    getContent(){
      axios.post(tools.url('/api/user/checkCertificate'), { user_has_courses_id: this.user_has_courses_id, module_id: this.module_id }).then((response)=>{
          this.documentUrl = response.data.documentUrl;
          this.order = response.data.order;
          this.$parent.getCourseTwo();

          var self = this;
          setTimeout(function(){
            if (response.data.nextmodule != null) {
              self.$router.push('/my-course/'+self.user_has_courses_id+'/module/'+response.data.nextmodule);
              
            }
          }, 2000);

         
        
          
      }).catch((error)=>{
        console.log(error);
      });
    },
    orderPrintend(){
      var addOns = {
        hasStandardShipping:false,
        hasDayShipping:false
      };
      if(this.order.hasship_standar == true){
          addOns.hasStandardShipping = true;
      }
      else if(this.order.hasship_fast == true){
          addOns.hasDayShipping = true;
      }
      localStorage.setItem("cart_addons",JSON.stringify(addOns));
      this.$router.push('/cart/'+this.order.course_id+'/'+this.order.id);
    },
    orderText(){
      /*var addOns = {
        hasAlert:true,
        
      };
     
      localStorage.setItem("cart_addons",JSON.stringify(addOns));
      this.$router.push('/cart/'+this.order.course_id+'/'+this.order.id);*/
      this.modal.icon = 'success';
      this.modal.block = false;
      this.modal.msg = 'Alert Sent';
      this.$refs.modalRes.open();
    },

  },

  beforeMount(){
    window.scrollTo(0, 0); // Subir hasta arriba del scroll
    this.setVals();
     this.getContent();
  }
}
</script>

