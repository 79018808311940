<template lang="html">
<div>
  <b-form @submit.prevent="onSubmit()">
    <h6 class="f-w-600">MY PHOTO</h6>
    <hr class="mb-4 c-hr" />

    <div class="row">
      <b-form-group class="col-12 cus-f-group-1" label="Photo *">
        <div class="d-block px-3 py-2">

          <input id="img-photo-i1" name="image" accept="image/*" type="file">

        </div>
      </b-form-group>
    </div>

    <b-button type="submit" class="btn-red btn-form" variant="primary" :disabled="disabled">Update photo</b-button>
  </b-form>

  <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
    <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
    {{modal.msg}}
    <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
    <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
    </div>
  </sweet-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
      user:{},

      states: [],
      towns: [],
      disabled: false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  watch:{
    'user.customer.state_id':function(val){
      if(val && !isNaN(val)){
        //this.getTowns(val);
      }
    }
  },

  methods: {
    onSubmit(){
      var formData = new FormData();


      formData.append("image", jQuery('input[name="image"]')[0].files[0]);

			/*var hayImagen = true;
				//if(jQuery('input[type=file]')[0].files[0] == undefined)

				if($('#fileinputone').prop("files")[0] == undefined){
					hayImagen = false;
				}
				if(hayImagen)
				{
					var formData = new FormData();
					//formData.append("image", jQuery('input[type=file]')[0].files[0]);
					formData.append("image",$('#fileinputone').prop("files")[0]);

					var imagenes_id = null;
					var url = "";
					var error = false;

					jQuery.ajax({

					   url:"https://1clickedu.com/apiV1/api/sync/save_img",
					    type: "POST",
					    headers: {

					    },
						processData: false,
					    contentType: false,
					    data: formData,
					    async:false,
					})
					.done(function(data, textStatus, jqXHR) {
					    console.log("HTTP Request Succeeded: " + jqXHR.status);
					    console.log(data);
					    imagenes_id = data.data.id;
					    //url = data.data.url;
					})
					.fail(function(jqXHR, textStatus, errorThrown) {
					    console.log("HTTP Request Failed");
					    //alert("Hubo un problema al guardar la imagen.");
					    error = true;
				})

				if(error)
				{
					this.$parent.showMessage("Hubo un problema al subir la imagen.","warning");
					return false;
				}

				this.row.foto = imagenes_id;

			}*/

      this.disabled = true;

      axios.post(tools.url('/api/user/image'), formData).then((response)=>{
        if(response.data.status == 'success'){
          this.disabled = false;
          this.modal.icon = "success";
          this.modal.msg = response.data.msg;
          this.modal.block = false;
          this.$refs.modal.open();
          this.$root.auth();
        }else{
          this.disabled = false;
          this.modal.icon = "error";
          this.modal.msg = response.data.msg;
          this.modal.block = false;
          this.$refs.modal.open();
        }
      }).catch((error)=>{
        this.disabled = false;
        this.handleErrors(error);
      });
    },

    handleErrors:function(errors){
      var err="";

      if (errors.response) {
        if(errors.response.data.errors){
          jQuery.each(errors.response.data.errors,(k,v)=>{
            err+="*"+v[0]+"\n";
          });
        }
      }
      else{
        console.log(errors);
        err="Error desconocido.";
      }

      this.modal.icon = "error";
      this.modal.msg = err;
      this.modal.block = false;
      this.$refs.modal.open();
    },
  },

  beforeMount(){
    // this.getStates();
  },

  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/");
     }
    // if(this.user.address && this.user.address.state_id){
    //   this.getTowns(this.user.address.state_id);
    // }
  }
}
</script>
