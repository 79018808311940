/*
 *
 * Estos componentes estan presentes solo en el website
 *
 */

const page="./page/";
// import slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueMeta from 'vue-meta';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Vidle from 'v-idle';
import InputMask from 'vue-input-mask';
import VTooltip from 'v-tooltip'
// import VueSocialSharing from 'vue-social-sharing';
// import VueSweetalert2 from 'vue-sweetalert2';

function plugin (Vue) {
	//Componentes llamados
  Vue.component('my-header', require(page+'shared/header.vue').default);
  Vue.component('my-footer', require(page+'shared/footer.vue').default);
	Vue.component('WhyComponent', require(page+'shared/why-component.vue').default);
	// Vue.component('slick',slick);
  Vue.use(BootstrapVue);
  Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
  // Vue.use(VueSocialSharing);
  // Vue.use(VueSweetalert2);
  Vue.use(VueMeta, {
		// optional pluginOptions
		refreshOnceOnNavigation: true
	});
  Vue.component(VueCountdown.name, VueCountdown);
	Vue.use(Vidle);
	Vue.component('input-mask', InputMask);
  Vue.use(VTooltip)
}

// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugin)
}

export default plugin
