<template lang="html">
  <b-form @submit="onSubmit">
    <h6 class="f-w-600">PASSWORD</h6>
    <hr class="c-hr" />

    <div class="row">


      <b-form-group class="col-12 cus-f-group-1 c-f-g-pass" label="New password *">
        <b-form-input id="i-2" type="password" v-model="form.npassword" required placeholder=""></b-form-input>
        <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpasstwo, 'fa-eye-slash' : !showpasstwo }"  @click="showpasstwo = !showpasstwo"></i>
      </b-form-group>

      <b-form-group class="col-12 cus-f-group-1 c-f-g-pass" label="Confirm password *">
        <b-form-input id="i-3" type="password" v-model="form.conpassword" required placeholder=""></b-form-input>
        <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpassthree, 'fa-eye-slash' : !showpassthree }"  @click="showpassthree = !showpassthree"></i>
      </b-form-group>
    </div>

    <b-button type="submit" class="btn-red btn-form" variant="primary">Change password</b-button>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
      <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
      {{modal.msg}}
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
      <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
      </div>
    </sweet-modal>

  </b-form>
</template>

<script>
export default {
  data(){
    return{
      showpass:false,
      showpasstwo:false,
      showpassthree:false,

      form: {
        password: '',
        npassword: '',
        conpassword: ''
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      if(this.form.npassword !== this.form.conpassword){

            this.modal.block = false;
            this.modal.icon = 'error';
            this.modal.msg = 'Las contrseñas no coiciden';
            this.$refs.modal.open();
            return;
        }

        this.modal.icon = "";
        this.modal.msg = 'Cargando...';
        this.modal.block = true;
        this.$refs.modal.open();

        axios.post(tools.url('/api/user/password'), this.form).then((response)=>{

                this.modal.block = false;
                this.modal.icon = "success";
                this.modal.msg = response.data.msg;

        }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = "error";
            this.modal.msg = error.response.data;

        });
    }
  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
        document.getElementById("i-1").type="text";
      }
      else if (val == false) {
        document.getElementById("i-1").type="password";
      }
    },

    'showpasstwo':function (val) {
      if (val == true) {
        document.getElementById("i-2").type="text";
      }
      else if (val == false) {
        document.getElementById("i-2").type="password";
      }
    },

    'showpassthree':function (val) {
      if (val == true) {
        document.getElementById("i-3").type="text";
      }
      else if (val == false) {
        document.getElementById("i-3").type="password";
      }
    }
  },
  mounted(){
    if(this.$root.logged == false){
         this.$router.push("/");
     }

  }
}
</script>
