<template lang="html">
  <div class="d-flex account-page">
    <div class="container">

      <div class="row">
        <div class="col-12 main-box">

          <div class="row">
            <div class="col-12 px-2">
              <h1 class="mb-3 page-title">{{ title }}</h1>
            </div>
            <div class="col-lg-4 px-2 col-menu">
              <div class="white-box">
                <div class="box-profile">
                  <div class="d-block mt-4 pb-3">
                    <!-- <span class="fa-stack fa-4x user-icon">
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fas fas fa-user fa-stack-1x fa-inverse"></i>
                    </span> -->

                    <div class="user-photo">
                      <div class="placed-backg box-photo" v-if="$root.user.image_url" v-bind:style="{ backgroundImage: 'url('+$root.user.image_url+')' }"></div>
                      <div class="placed-backg box-photo" v-else v-bind:style="{ backgroundImage: 'url(/public/images/shared/user.svg)' }"></div>
                    </div>

                    <h5 class="txt-username">Welcome {{$root.user.nombre}}</h5>
                  </div>
                </div>

                <hr />

                <div class="box-menu">
                  <p class="item">
                    <router-link class="btn-menu" to="/account"><i class="far fa-edit"></i> My information</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/account/my-photo"><i class="far fa-edit"></i> My photo</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/account/password"><i class="fas fa-lock-alt"></i> Change password</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/account/my-courses"><i class="far fa-graduation-cap"></i> My courses</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/account/share-course"><i class="fas fa-share-square"></i> Share courses</router-link>
                  </p>
                  <p class="item">
                    <router-link class="btn-menu" to="/account/my-certificates"><i class="far fa-file-certificate"></i> My certificates</router-link>
                  </p>

                  <!-- <p class="item">
                    <router-link class="btn-menu" to="/"><i class="fas fa-sign-out-alt"></i> Cerrar sesión</router-link>
                  </p> -->
                  <p class="item">
                    <a class="btn-menu" @click="logout()" style="cursor: pointer;"><i class="fas fa-sign-out-alt"></i> Log out</a>
                  </p>
                </div>

              </div>
            </div>

            <div class="col-lg px-2">
              <div class="white-box p-4">

                <router-view></router-view>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      title: 'My account',
      photo: 'public/images/pages/contact/image.jpg',
    }
  },

  methods:{
    getUrlName(){
      var urlName = this.$route.path;

      if(urlName == '/account'){ this.title = 'My information' }
      if(urlName == '/account/password'){ this.title = 'Change password' }
      if(urlName == '/account/my-photo'){ this.title = 'My photo' }
      if(urlName == '/account/my-courses'){ this.title = 'My courses' }
      if(urlName == '/account/my-certificates'){ this.title = 'My certificates' }
      if(urlName == '/account/share-course'){ this.title = 'Share courses' }
    },

    logout(){
        axios.post(tools.url("/logout")).then((response)=>{
            this.$parent.user = {};
            this.$parent.logged = false;
            this.$router.push('/access');
        }).catch(()=>{});
    },
  },

  beforeMount(){
    this.getUrlName();
  },

  watch: {
    $route(to, from) {
      this.getUrlName();
    },
  },

  mounted(){
     if(this.$root.logged == false){
         this.$router.push("/");
     }
     this.$root.auth();
     this.$router.push("/account/my-courses");
  }
}
</script>
