<template lang="html">
  <div id="course-page">

    <section class="container oversized-container top-content">
      <h1 class="mb-1 g-title"><router-link :to="('/my-course/'+user_has_courses_id)">{{ course.name}}</router-link></h1>
    </section>

    <section class="container oversized-container content-section">
      <div class="row _main-row">

        <!-- Modules -->
        <div class="col-lg-4 col-xl-3 col-modules">

          <!-- Importante. Aqui la sección de enlaces a modulos varía. -->
          <div class="box-modules" v-if="$route.name == 'examPage'">
            <div class="items">
              <a class="_link active"><strong>Choose the best answer</strong></a>
            </div>
          </div>

          <div class="box-modules" v-else>
            <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modalloadinfg">
              <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
              <div v-html="modal.msg"></div>

            </sweet-modal>
            <sweet-modal ref="modalalert" icon="warning" hide-close-button blocking>
                <b>Inactivity Warning</b>
                <p>Your progress will be saved and you will be logged out if CONTINUE is not selected in 1 minute</p><br>
                <button class="btn btn-danger"  @click="closeModal">CONTINUE</button>
            </sweet-modal>
            <v-idle :events="['mousemove']" :duration="course.inactivityTime"  @idle="onidle" v-show="showtimer"/>
            <div class="items" v-for="(module, index) in course.modules" :key="index">

              <router-link v-if="module.active" class="_link"
                v-bind:class="{ 'active' : (module_id == module.id), 'completed' : module.completed}"
                :to="'/my-course/'+user_has_courses_id+'/module/'+module.id">
                <!-- <strong>MÓDULE: </strong> -->
                <span class="title">{{ module.title }}</span>
                <!-- <span class="time">{{ module.time }} - {{ module.timemax }} hrs</span> -->
              </router-link>


              <a v-else class="_link"
                v-bind:class="{ 'active' : (module_id == module.id)}"
                >
                <!-- <strong>MÓDULE: </strong> -->
                <span class="title">{{ module.title }}</span>
                <!-- <span class="time">{{ module.time }} - {{ module.timemax }} hrs</span> -->
              </a>


            </div>
          </div>
          <!-- end -->

        </div>
        <!-- end modules -->

        <!-- Main content -->
        <div class="col col-lg-8 col-xl-9 col-main-section">
          <router-view></router-view>
        </div>
        <!-- end -->

        <button @click="bottomFunction()" id="myBtn" title="Go to bottom"><i class="fas fa-arrow-to-bottom"></i></button>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      user_has_courses_id: null,
      module_id: null,
      showtimer:false,
      modules: [],
      course:{},
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      isopen:false
    }
  },

  methods: {
    setVals(){
      this.user_has_courses_id = this.$route.params.user_has_courses_id;
      this.module_id = this.$route.params.module_id;
    },

    validateCourseID(){
      if(!this.user_has_courses_id){ // Si no trae la ID del curso, mandar a error 404
        this.$router.push("/404");
      }
    },

    getCourse(){
      this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Loading, please wait...';
        this.$refs.modalloadinfg.open();
      axios.get(tools.url("/api/user/mycourses/"+this.$route.params.user_has_courses_id)).then((response)=>{
        this.modal.block = false;
        this.$refs.modalloadinfg.close();
          this.course = response.data;
          

          if (this.course.modules.length > 0) {

            this.$router.push('/my-course/'+this.$route.params.user_has_courses_id+'/module/'+this.course.modules[this.course['indexlastcomplete']].id);
          }

        }).catch((error)=>{
          this.modal.block = false;
            this.$refs.modalloadinfg.close();
        });
    },
    getCourseTwo(){
      axios.get(tools.url("/api/user/mycourses/"+this.$route.params.user_has_courses_id)).then((response)=>{
        this.course = response.data;
        }).catch((error)=>{
        });
    },
    saveSession(){
      if(this.$route.meta.title == 'My course'){
        axios.get(tools.url("/api/user/savesession/"+this.$route.params.user_has_courses_id)).then((response)=>{
          this.courses = response.data;

        }).catch((error)=>{
        });
      }

    },
    onidle(){
        if(this.course.inactivityTime > 0){
            if (this.isopen == false) {

              if (!this.$route.path.includes('exam-finished')){

                if (this.$route.path.includes('module')){
                  this.isopen = true;
                 // this.$refs.modalalert.open();
                }
              }

              
            }
        }

    },
    closeModal(){
      this.isopen = false;
      this.$refs.modalalert.close();
      this.$router.push('/account/my-courses');
    },
    bottomFunction() {

      window.scrollTo(0,document.body.scrollHeight);

    }
  },

  watch: {
    "$route" : function(v){
      this.setVals();
      //this.validateCourseID();
      //this.getCourse();
    },
  },
  mounted() {
    this.setVals();
    this.validateCourseID();
    this.getCourse();

    var self = this;
    setInterval(self.saveSession, 15000);

    /*let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(alert('Sigues ahi'), 10000)
   }*/

  },
  beforeMount(){

  },
}
</script>
<style>

#myBtn {

  position: fixed;
  bottom: 20px;
  right: 90px;
  z-index: 99;
  font-size: 20px;
  border: none;
  outline: none;
  background-color: #ce0015;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 30px;
  width:60px
}

#myBtn:hover {
  background-color: #555;
}
</style>
