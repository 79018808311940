<template lang="html">
    <div id="enroll-page">
  
      <section class="main-section">
 
  
        <b-form class="container oversized-container main-container" >
          <div class="row" v-if="step == 1">
  
          
  
          </div>
  
          <div class="row" v-if="step == 2">
            <!-- Column -->
            <div class="col-lg-12 mb-4">
              <div class="box-white box-thanks h-100">
                <p class="mb-3">
                  <i class="fal fa-check-circle check-icon"></i>
                </p>
  
                <!-- <h3 class="txt-purchased">You have purchase {{data.order.total_courses}} course:</h3> -->
                <h3 class="txt-purchased">Thank you for your purchase of the following course!</h3>
                
                <div v-for="(detail,indx) in data.details" :key="indx">
                  <h2 class="txt-course-name">{{detail.course}}</h2>
                  <p class="mt-4">Course ID: <b>{{detail.course_id }}</b></p>
                  <p class="mt-4">Quantity purchased: <b>{{detail.quantity }}</b></p>
                  <p class="mt-4">Course price: <b>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(detail.price) }} USD</b></p>

                  <p class="mt-4">Add-ons:</p>  
                  
                      <p v-if="detail.hasAudioNarrator == 1">- Audio Narrator</p>
                      <p v-if="detail.hasAlert == 1">- Text/Email alert upon submission of credits to the state</p>
                      <p v-if="detail.hasStandardShipping == 1">- Professionally printed certificate: <b>Standard Shipping</b></p>
                      <p v-if="detail.hasDayShipping == 1">- Professionally printed certificate: <b>1-2 Day Shipping</b></p>
                  
                  

                  <hr>
                </div>

                <p class="mt-4">Total Add-ons: <b>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.order.total_adds) }} USD</b></p>
                
                <p class="mt-4"><b>Grand total: ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.order.total) }} USD</b></p>
  
                <h5 class="txt-operation-id">Transaction ID: <u>{{data.order.id}}</u></h5>
  
                <p class="mt-4">Please check your email for your receipt and instructions!</p>


                <b-form class="container oversized-container main-container" @submit="onSubmit" autocomplete="off" v-if="data.order.requiredId == 1 || data.order.requireBirthday == 1" >
                    <div class="row">
                      <div class="col-3" v-if="data.order.requiredId == 1"></div>
                      <b-form-group class="col-lg-6 cus-f-group-1" label="Identification" v-if="data.order.requiredId == 1">
                        <input id="img-photo-i1" name="image" accept="image/*,.pdf" type="file">

                      </b-form-group>
                      <div class="col-3" v-if="data.order.requiredId == 1"></div>
                      <div class="col-3" v-if="data.order.requireBirthday == 1"></div>
                      <b-form-group class="col-lg-6 cus-f-group-1" label="Date of Birthday" v-if="data.order.requireBirthday == 1">
                        <b-form-input type="date" v-model="userform.fecha_nacimiento" placeholder=""></b-form-input>
                      </b-form-group>

                      <div class="col-3" v-if="data.order.requireBirthday == 1"></div>
                     
                      <b-button type="submit" class="col-lg-6 btn-submit">Save information</b-button>

                    </div>
                    
                    
                    
                </b-form>
  
                <p class="mt-4">
                  <router-link class="btn btn-red- f-w-600" to="/account/my-courses" v-if="$root.logged == true">Go to my profile</router-link>
                  <router-link class="btn btn-red- f-w-600" to="/access" v-else>Go to my profile</router-link>
                </p>
              </div>
            </div>
            <!--  -->
          </div>
        </b-form>
  
        <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
          <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
          <div v-html="modal.msg"></div>

        </sweet-modal>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
        step:2,
        data: {
          details:[]
        },
        userform:{
            fecha_nacimiento:null,
            user_id:null,

        },
        modal:{
          msg:'',
          icon:'',
          block:false,
        },
  
      }
    },
    
    methods: {
      getInfo(){
        axios.get(tools.url('/api/order/' + this.$route.params.id)).then((response)=>{
          this.data = response.data;
          this.userform.user_id = this.data.user.id;
          if (!this.$root.logged) {
            this.$root.user = response.data.user;
            this.$root.logged = true;
            axios.get('/sanctum/csrf-cookie').then(() => {

            });
          }
          
          this.sendToAnalitycs();


       }).catch((error)=>{
         console.log(error);
       });
      },
      sendToAnalitycs(){

        var productsdata = [];

        for (let z = 0; z < this.data.details.length; z++) {
            /*var aux = {
                id:this.data.details[z]['id'],
                name:this.data.details[z]['course'],
                price:this.data.details[z]['price'],
                quantity:1,
                
            };*/
            //productsdata.push(aux);
            this.$ga.ecommerce.addItem({
              id:this.data.details[z]['id'],
                name:this.data.details[z]['course'],
                price:this.data.details[z]['price'],
                quantity:1,
            });
          
        }
        const orderData = {
          id: this.data.order.id,
          affiliation: this.data.user.nombre+' '+this.data.user.apellidos,
          revenue: this.data.order.total, // Ingresos generados por la transacción
          shipping: 0, // Costo de envío
          tax: 0, // Impuesto
          //products: productsdata,
        };

        
        this.$ga.ecommerce.addTransaction({
            id: this.data.order.id,
            affiliation: this.data.user.nombre+' '+this.data.user.apellidos,
            revenue: this.data.order.total, // Ingresos generados por la transacción
            shipping: 0, // Costo de envío
            tax: 0, // Impuesto
        });
        this.$ga.ecommerce.send();

       // this.$ga.event('ecommerce', 'purchase', JSON.stringify(orderData));

        //this.$ga.ecommerce('purchase', JSON.stringify(orderData));

      },
      onSubmit(event) {

          event.preventDefault();

          this.modal.block = true;
          this.modal.icon = "";
          this.modal.msg = "Loading";
          this.$refs.modal.open();

          var formData = new FormData();

          formData.append('fecha_nacimiento',this.userform.fecha_nacimiento);
          formData.append('user_id',this.userform.user_id);
      

          if (typeof jQuery('input[name="image"]')[0].files[0] !== 'undefined') {
              formData.append("image", jQuery('input[name="image"]')[0].files[0]);
          }


          axios.post(tools.url("/api/saveInformationOrder"),formData).then((response)=>{
            this.modal.block = false;
            this.modal.icon = "success";
            this.modal.msg = "Information saved correctly ";

          }).catch((error)=>{
            this.modal.block = false;
            this.modal.icon = 'error';
            this.modal.msg = error.response;
          });
      }
    },

    mounted(){
     
      this.getInfo();
    }
  }
  </script>
  