<template lang="html">
<div id="my-certificates-page">
  <div>
    <h6 class="f-w-600">OBTAINED CERTIFICATES</h6>
    <hr class="mb-4 c-hr" />

    <div class="box-courses">
      <div class="box-course-sample-2" v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
        <div class="col col-image">
          <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+ c.image_url +')' }"></div>
        </div>

        <div class="col col-description">
          <div class="status">Completed <i class="far fa-check-circle"></i></div>
          <h6 class="c-name completed">{{c.category.name}}</h6>
          <h4 class="c-desc">{{ c.name }}</h4>

          <div class="row no-gutters box-progress">
            <div class="col col-number">
              100%
            </div>

            <div class="col col-bar">
              <b-progress height="6px" :value="100" :max="100"></b-progress>
            </div>
          </div>

          <h6 class="c-update">Completed: <strong> {{c.date_complete}}</strong></h6>

          <div class="row no-gutters box-buttons">
            <div class="col-12">
              <router-link target="_blank" class="_btn bg-red" :to="'/course/'+c.id">Go to course</router-link>
            </div>
          </div>
        </div>

        <div class="col col-certificates">
          <a class="t-250 _btn" :href="c.documentUrl" target="_blank">Download certificate (pdf)</a>
          <router-link class="t-250 _btn" :to="'/cart/'+c.course_id+'/'+c.orderdetail_id">Order a printed document</router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data(){
    return{
      courses: [
       
      ],
    }
  },

  methods: {
    getCertiificate(){
      axios.get(tools.url("/api/user/mycertificate")).then((response)=>{
          this.courses = response.data;
          
        }).catch((error)=>{
        });
    },
  },

  beforeMount(){
  },

  mounted(){
     if(this.$root.logged == false){
         this.$router.push("/");
     }
     this.getCertiificate();
  }
}
</script>
