<template lang="html">
  <div id="courses-results-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12 col-title">
          <div class="box-simple-title search">
            <h6>Courses / Search</h6>
          </div>

          <div class="box-title search">
            <h2><span>Buscar: </span>{{ keywords }}</h2>
          </div>
        </div>
      </div>

      <div class="row row-items-xl-4">
        <div class="col-md-6 col-lg-4 mb-4 box-course-sample-1" v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
          <router-link class="box-course" :to="'/courses/checkout/'+c.state+'/'+c.custom_url">
            <div class="box-image" v-bind:style="{ backgroundImage: 'url('+c.image_url+')' }" style="background-size: contain !important;">
            </div>

            <div class="box-description">
              <h6 class="c-req">{{c.state}}</h6>
              <h6 class="c-name">{{c.name}}</h6>
              <div class="c-desc" v-html="c.description"></div>

             <!--  <h6 class="c-req">No test or quiz required</h6>

              <h6 class="c-cer">Certificate emailed immediately upon completion.</h6> -->

              <div class="row box-button">
                <div class="col-lg-5 col-more">
                  <h6 class="c-more">See more information</h6>
                </div>

                <div class="col-lg-7">
                  <span class="box-price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.price) }} USD</span>
                </div>

                <div class="col-12 text-center">
                  <a class="btn-enroll" @click="$root.enroll(c, $event)">Enroll</a>

                  <h6 class="c-inf">Ask for information 1-800-560-1611 </h6>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <WhyComponent></WhyComponent>

  </div>
</template>

<script>
export default {
  data(){
    return{
      keywords: null,

      courses: [

      ],
    }
  },
  methods:{
    getCourses(){
      axios.post(tools.url("/api/coursesseach"),{keywords:this.keywords}).then((response)=>{
          this.courses = response.data;
        }).catch((error)=>{
        });
    },
  },

  beforeMount(){
    this.keywords = this.$route.query.keywords;
    this.getCourses();
  },

  watch: {
    '$route.query.keywords':function(){
      this.keywords = this.$route.query.keywords;
      this.getCourses();
    },
  },

}
</script>
