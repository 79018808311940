<template lang="html">
    <div class="container" id="text-page">
      <h1 class="page-title txt-black">PRIVACY, CONFIDENTIALITY, AND SECURITY POLICY</h1>
      <div v-html="privacy.body"></div>
      
    </div>
  </template>
  
  <script>
      export default {
          data(){
              return {
                  privacy:{
                      body:'',
                  },
  
              }
          },
  
          methods:{
              getTerminos(){
                  axios.get(tools.url("/api/privacidad")).then((response)=>{
                      this.privacy = response.data;
                  }).catch((error)=>{
                  });
              },
              
          },
  
          mounted(){
              this.getTerminos();
          }
      }
  </script>
  