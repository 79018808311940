<template lang="html">
    <div id="article-page">
  
      <div class="container content-section">
        <!-- <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue">Comunicados</h5>
          </div>
        </div> -->
  
        <div class="row">
          <!-- Artículo actual -->
          <div class="col-lg-12 col-article">
            <div class="mb-3 box-image">
              <img :src="noticia.imageUrl">
            </div>
            <h1 class="title">{{ noticia.title }}</h1>
            <div class="descr" v-html="noticia.body"></div>
          </div>
          <!--  -->
  
          <!-- Artículos -->
          <!-- <div class="col-lg-4 col-articles">
            <div class="row">
  
              <div class="col-12 px-0 article-sample-1" v-for="(article, arInx) in blogs" :key="'ar-'+arInx">
                <router-link class="t-250 placed-backg article-link" v-bind:style="{ backgroundImage: 'url('+article.imageUrl+')' }" :to="'/comunicados/'+article.id">
                  <img class="empty" src="public/images/shared/empty.png">
  
                  <div class="inside">
                    <div class="t-250 show">Ver más</div>
                    <div class="shadow"></div>
                    <div class="box-txt">
                      <h5>{{article.title}}</h5>
                    </div>
                  </div>
                </router-link>
              </div>
              
            </div>
          </div> -->
          <!--  -->
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
        noticia: {
        },
        blogs: [
         
        ],
        id: null,
      }
    },
    watch:{
        '$route.params.id':function(){
             this.id = this.$root._getURLID(this.$route.params.id);
            this.getBlogs();
        }
    },
  
    methods: {
      getBlogs: function(){
        axios.get(tools.url('/api/blogs/'+this.id)).then((response)=>{
            
            this.noticia = response.data;
        });
      }
    },
  
    mounted(){
       this.id = this.$route.params.id;
       this.getBlogs();
    },
  }
  </script>
  