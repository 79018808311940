<template lang="html">
  <div id="my-courses-page" v-if="!isLoading">
    <div>
      <h6 class="f-w-600">ACTIVE COURSES</h6>
      <hr class="mb-4 c-hr" />
  
      <div class="box-courses" v-if="courses.length > 0">
        <active-course-component :course="course" v-for="(course, cInx) in courses" :key="'cInx-'+cInx"></active-course-component>
      </div>
      <div class="box-courses" v-else>
        <p style="text-align: center;">No active courses at the moment</p>
      </div>
      <!-- <div class="box-more-courses">
        <img class="icon" src="/public/images/pages/courses/course-icon.png">
        <h6 class="txt">Learn more!</h6>
        <router-link class="txt text-red" to="/">See more courses</router-link>
      </div> -->
    </div>
  
  </div>
  <div id="my-courses-page" v-else style="text-align: center;">
        <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br>
  </div>  
  </template>
  
  <script>
  import ActiveCourseComponent from './components/active-course-component.vue'
  export default {
    components: {
      ActiveCourseComponent
    },
  
    data(){
      return{
        formAddons: {
          narrator: null,
        },
  
        courses: [
        
        ],
        isLoading:true,
      }
    },
  
    methods: {
      getCourses(){
        this.isLoading = true;
        axios.get(tools.url("/api/user/mycourses")).then((response)=>{
            this.courses = response.data;
            this.isLoading = false;
          }).catch((error)=>{
            this.isLoading = false;
          });
      },
    },
  
    beforeMount(){
    },
  
    mounted(){
       if(this.$root.logged == false){
           this.$router.push("/");
       }
       this.getCourses();
    }
  }
  </script>
  